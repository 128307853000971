import React from 'react'
import Layout from '../../components/Layout'

export default function Test() {
    return (
        <Layout>
            <div>Test</div>
        </Layout>
    )
}
